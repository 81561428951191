.buttonBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20vh;
  min-width: 35vw;
}

.theButtons {
  display: flex;
  justify-content: center;
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 100px;
  cursor: pointer;
}

.theButtons .btnClass {
  background-color: white;
}

.btnClass {
  height: 25px;
  border-radius: 100px;
}

@media (min-width: 600px) {
  .buttonBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
    min-width: 10vw;
  }

  .theButtons {
    display: flex;
    justify-content: center;
    border: none;
    height: 35px;
    width: 35px;
    border-radius: 100px;
    cursor: pointer;
  }

  .btnClass {
    height: 35px;
    border-radius: 100px;
  }

  .theButtons .btnClass {
    background-color: white;
  }
}
