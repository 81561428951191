.imgBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  min-height: 20h;
  min-width: 30vw;
  margin-top: 50px;
}

#logo-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
}

@media (min-width: 600px) {
  .imgBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20rem;
    min-width: 26rem;
    margin: 0;
    /* background-color: red; */
  }

  #logo-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 320px;
    /* background-color: brown; */
  }

  .cineBox {
    display: block;
    position: absolute;
    min-height: 4rem;
    min-width: 6rem;
    margin: 0;
    margin-top: 16.5rem;
    margin-left: 3rem;
    /* background-color: blueviolet; */
  }

  #cannes-img {
    height: 120px;
  }
}