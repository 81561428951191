.workBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-width: 100vw;
  min-height: 85vw;
}

.listBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  min-width: 100vw;
  min-height: 50vh;
}

.videosBox {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45vw;
  min-height: 30vh;
}

.videosBox iframe {
  width: 320px;
}

.listItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0;
  min-width: 15vw;
  min-height: 20vh;
}

.listItems .everyBtn {
  min-width: 20vw;
  min-height: 12vh;
  cursor: pointer;
  font-family: "Futura", sans-serif;
  font-size: 10px;
  letter-spacing: 1px;
  text-align: center;
}

.appPage .everyBtn {
  border: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  color: white;
  background-color: black;
  border-color: white;
}

.appPage .everyBtn:hover {
  color: black;
  background-color: white;
  border-style: solid;
  border: 1px black;
  transition: 0.5s ease-in-out;
}

.light .everyBtn {
  border: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  color: black;
  background-color: white;
  border-color: black;
}

.light .everyBtn:hover {
  color: white;
  background-color: black;
  border: 1px solid white;
  transition: 0.5s ease-in-out;
}

#item-1,
#item-2,
#item-3,
#item-4,
#item-5,
#item-6 {
  display: flex;
  flex-direction: column;
}

#item-1 #text-1,
#item-2 #text-2,
#item-3 #text-3,
#item-4 #text-4,
#item-5 #text-5,
#item-6 #text-6 {
  text-align: justify;
  /* text-align-last: left; */
  height: 0;
  visibility: hidden;
  opacity: 0;
  padding: 0;
  animation: slow-out 1s ease-in-out 1;
  font-size: 9px;
}

@keyframes slow-out {
  0% {
    height: 17vh;
    opacity: 1;
    visibility: visible;
    padding: 5px;
  }

  25% {
    opacity: 0;
  }

  100% {
    height: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0;
  }
}

#display {
  display: flex;
  flex-direction: column;
}

#display .everyText {
  text-align: justify;
  text-align-last: left;
  height: 17vh;
  visibility: visible;
  opacity: 1;
  padding: 5px;
  animation: slow-in 1s ease-in-out 1;
  font-size: 9px;
}

@keyframes slow-in {
  0% {
    height: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    height: 17vh;
    opacity: 1;
    visibility: visible;
    padding: 5px;
  }
}

@media (min-width: 600px) {
  .workBox {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    min-height: 85vh;
    width: 100vw;
  }

  .listBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    min-width: 28rem;
    min-height: 34rem;
    max-height: 36rem;
    /* background-color: red; */
  }

  .videosBox {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    min-width: 50vw;
    min-height: 75vh;
    /* background-color: yellow; */
  }

  .videosBox .mainVideo {
    height: 70vh;
  }

  .videosBox iframe {
    height: 560px;
    width: 800px;
  }

  .listItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 26rem;
    min-height: 28rem;
    padding-left: 2rem;
    /* background-color: aqua; */
  }

  .listItems .everyBtn {
    min-width: 10rem;
    min-height: 2.5rem;
    cursor: pointer;
    font-family: "Futura", sans-serif;
    font-size: 16px;
    letter-spacing: 0.1rem;
    /* background-color: blue; */
  }

  #item-1,
  #item-2,
  #item-3,
  #item-4,
  #item-5,
  #item-6 {
    display: flex;
    flex-direction: column;
  }

  #item-1 #text-1,
  #item-2 #text-2,
  #item-3 #text-3,
  #item-4 #text-4,
  #item-5 #text-5,
  #item-6 #text-6 {
    text-align: left;
    word-wrap: normal;
    height: 0;
    visibility: hidden;
    opacity: 0;
    padding: 0;
    animation: slow-out 1s ease-in-out 1;
    font-size: 15px;
    width: 26rem;
    /* background-color: yellow; */
  }

  @keyframes slow-out {
    0% {
      height: 9rem;
      opacity: 0;
      visibility: hidden;
      padding: 10px;
    }

    25% {
      opacity: 0;
    }

    100% {
      height: 0;
      opacity: 0;
      visibility: hidden;
      padding: 0;
    }
  }

  /* #display .everyText {
    background-color: blueviolet;
    font-size: 15px;
    width: 26rem;
  } */

  #display .everyText {
    text-justify: distribute;
    /* word-wrap: break-word; */
    height: 12rem;
    visibility: visible;
    opacity: 1;
    padding: 10px;
    animation: slow-in 1s ease-in-out 1;
    font-size: 15px;
    line-height: 1.3rem;
    /* letter-spacing: 0.1rem; */
    z-index: 1;
    min-width: 29rem;
    /* background-color: blueviolet; */
    white-space: pre-line;
    padding-bottom: 2rem;
  }

  .block {
    display: none;
  }

  @keyframes slow-in {
    0% {
      height: 0;
      opacity: 0;
      visibility: hidden;
      padding: 0;
    }

    70% {
      opacity: 0;
    }

    100% {
      height: 12rem;
      opacity: 1;
      visibility: visible;
      padding: 10px;
    }
  }
}